













































































































import Vue from 'vue';

interface CProps {
  icon: 'well' | 'blind' | 'knowledge';
  title: string;
  subtext: string;
}

export default Vue.extend<{}, {}, {}, CProps>({
  name: 'info-card',
  props: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon: String as any,
    title: String,
    subtext: String,
  },
});
