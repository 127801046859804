




























































































































import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import AccountDetails from '@/components/AccountDetails.vue';
import AnimateIntoView from '@/components/AnimateIntoView.vue';
import InfoCard from '@/components/InfoCard.vue';
import LoginSignUp from '@/components/LoginSignUp.vue';
import ProfileCard from '@/components/ProfileCard.vue';

interface CData {
  showNav: boolean;
  showAuth: boolean;
  observer: IntersectionObserver | null;
}

interface CComputed {
  username: string | null;
  isLoggedin: boolean;
}

export default Vue.extend<CData, {}, CComputed>({
  name: 'home',
  data: () => ({
    showNav: false,
    showAuth: false,
    observer: null,
  }),
  computed: {
    ...mapGetters(['isLoggedin']),
    ...mapState(['username']),
  },
  mounted() {
    this.observer = new IntersectionObserver(
      ([entry]) => {
        this.showNav = entry.intersectionRatio < 0.1;
      },
      { threshold: 0.1 }
    );

    if (this.$refs.banner) {
      this.observer.observe(this.$refs.banner as Element);
    }
  },
  beforeDestroy() {
    // eslint-disable-next-line no-unused-expressions
    this.observer?.disconnect();
  },
  components: {
    AccountDetails,
    AnimateIntoView,
    InfoCard,
    LoginSignUp,
    ProfileCard,
  },
});
